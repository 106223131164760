import React, { useEffect, useState } from "react";
import FaqComponent from "react-faq-component";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "What is the total Xin Bears supply?",
      content: `888.`,
    },
    {
      title: "How much is mint price?",
      content: "0.48 SOL",
    },
    {
      title: "When is mint date?",
      content: `23 Feb Wednesday 7PM UTC`,
    },
    {
      title: "Where can I buy or sell my Xin Bears after mint?",
      content: `Magic Eden.`,
    },
    {
      title: "What should I do to win the Baby Xin Bear?",
      content: `You must hold 2 Xin Bears`,
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "rgb(68, 68, 68)",
  rowTitleColor: "rgb(68, 68, 68)",
  // rowContentColor: 'grey',
  arrowColor: "rgb(68, 68, 68)",
  rowContentPaddingTop: "10px",
  rowContentPaddingLeft: "15px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingRight: "10px",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

export default function Faq() {
  const [rows, setRowsOption] = useState(null);

  useEffect(() => {
    if (rows) {
      rows[0].expand();
    }
  }, [rows]);
  return (
    <div>
      <FaqComponent
        data={data}
        styles={styles}
        config={config}
        getRowOptions={setRowsOption}
      />
    </div>
  );
}
